import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ref, computed, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { changeIdePwd, logout } from '@/service/index.js';
import { alertMessage, clearUserInfo, blurAllInput } from '@/assets/js/tool.js';
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    const showUpdatePwd = computed(() => store.state.showUpdatePwd);
    const userInfo = computed(() => store.getters.getUserInfo);
    const lang = computed(() => store.getters.getLang);
    /**点击退出后 需要跳转到首页的页面*/
    const logoutPage = computed(() => store.state.logoutPage);
    /**控制dialog显示与隐藏*/
    const dialogVisible = ref(false);

    /**form值*/
    const ruleForm = ref({
      oldPassword: '',
      newPassword: '',
      againPassword: ''
    });

    /**控制密码显示与隐藏*/
    const passwordShow = ref({
      oldPassword: false,
      newPassword: false,
      againPassword: false
    });

    /**确认密码验证*/
    const validatePwdOld = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.currentPwd')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.password')));
      } else if (value.length < 6) {
        callback(new Error(t('rules.pwdLength')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };
    const validatePassAgain = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.confirm')));
      } else if (value.length < 6) {
        callback(new Error(t('rules.pwdLength')));
      } else if (value !== ruleForm.value.newPassword) {
        callback(new Error(t('rules.differentPwd')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };

    /**验证柜子*/
    const rules = ref({
      oldPassword: [{
        required: true,
        validator: validatePwdOld,
        trigger: 'blur'
      }],
      newPassword: [{
        required: true,
        validator: validatePass,
        trigger: 'blur'
      }],
      againPassword: [{
        required: true,
        validator: validatePassAgain,
        trigger: 'blur'
      }]
    });

    //ref
    const ruleFormRef = ref(null);

    //router
    const router = useRouter();

    //watch
    watch(showUpdatePwd, async newVal => {
      dialogVisible.value = newVal;
    });
    watch(dialogVisible, async newVal => {
      if (newVal) {
        passwordShow.value = {
          oldPassword: false,
          newPassword: false,
          againPassword: false
        };
        await nextTick();
        ruleFormRef.value.resetFields();
      }
    });

    //function
    /**关闭*/
    function close() {
      store.commit('setShowUpdatePwd', false);
    }

    /**提交*/
    async function submit(isBlur = false) {
      if (isBlur) blurAllInput('.update-pwd-form');
      await nextTick();
      ruleFormRef.value.validate(async (valid, fields) => {
        if (valid) {
          let data = {
            email: userInfo.value.email || '',
            oldPassword: ruleForm.value.oldPassword,
            newPassword: ruleForm.value.newPassword
          };
          let result = await changeIdePwd(data);
          if (result.status === 0) {
            alertMessage('success', t('success.changePwd'));
            close();
            setLogout();
          } else {
            let text = result ? result.msg || t('failed.changePwd') : t('failed.changePwd');
            alertMessage('error', text);
          }
        }
      });
    }

    /**退出登录*/
    async function setLogout() {
      let result = await logout();
      if (result.status === 0) {
        clearUserInfo(); //清理用户缓存信息
        let path = router.currentRoute.value.path;
        if (logoutPage.value.indexOf(path) > -1) router.push('/');
      } else {
        let text = result ? result.msg || t('failed.signOut') : t('failed.signOut');
        alertMessage('error', text);
      }
    }
    return {
      //data
      lang,
      dialogVisible,
      ruleForm,
      rules,
      passwordShow,
      //ref
      ruleFormRef,
      //function
      close,
      submit
    };
  }
};