import "core-js/modules/es.error.cause.js";
import { ref, computed, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { sendCodeEmail, resetPassword } from '@/service/index.js';
import { alertMessage, blurAllInput } from '@/assets/js/tool.js';
import { getLocalStorage } from '@/assets/js/storage.js';
export default {
  setup() {
    //data
    const {
      t
    } = useI18n();
    const store = useStore();
    const showResetPwd = computed(() => store.state.showResetPwd);
    /**控制dialog显示与隐藏*/
    const dialogVisible = ref(false);
    /**当前重置的邮箱*/
    const email = ref('');
    /**倒计时*/
    const countDown = ref(59);
    const showCountDown = ref(false);
    let interval = null;
    const disabledEmail = ref(false);

    /**form值*/
    const ruleForm = ref({
      email: '',
      emailCode: '',
      newPassword: '',
      againPassword: ''
    });

    /**控制密码显示与隐藏*/
    const passwordShow = ref({
      newPassword: false,
      againPassword: false
    });

    /**邮箱格式验证*/
    const validateEmail = (rule, value, callback) => {
      let patt = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (value) {
        if (patt.test(ruleForm.value.email)) {
          callback();
        } else {
          callback(new Error(t('rules.emailFormat')));
        }
      } else {
        callback(new Error(t('rules.email')));
      }
    };
    /**确认密码验证*/
    const validatePass = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.password')));
      } else if (value.length < 6) {
        callback(new Error(t('rules.pwdLength')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };
    const validatePassAgain = (rule, value, callback) => {
      let reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (value === '') {
        callback(new Error(t('rules.confirm')));
      } else if (value.length < 6) {
        callback(new Error(t('rules.pwdLength')));
      } else if (value !== ruleForm.value.newPassword) {
        callback(new Error(t('rules.differentPwd')));
      } else if (reg.test(value)) {
        callback(new Error(t('rules.pwdType')));
      } else {
        callback();
      }
    };
    /**是否为空验证*/
    const validateRequired = (rule, value, callback) => {
      let field = rule.field;
      let errorText = t(`rules.${field}`);
      if (value.trim() === '') {
        callback(errorText);
      } else {
        callback();
      }
    };

    /**验证柜子*/
    const rules = ref({
      email: [{
        required: true,
        validator: validateEmail,
        trigger: 'blur'
      }],
      emailCode: [{
        required: true,
        validator: validateRequired,
        trigger: 'blur'
      }],
      newPassword: [{
        required: true,
        validator: validatePass,
        trigger: 'blur'
      }],
      againPassword: [{
        required: true,
        validator: validatePassAgain,
        trigger: 'blur'
      }]
    });

    //ref
    const ruleFormRef = ref(null);

    //watch
    watch(showResetPwd, async newVal => {
      dialogVisible.value = newVal;
    });
    watch(dialogVisible, async newVal => {
      if (newVal) {
        disabledEmail.value = false;
        passwordShow.value = {
          newPassword: false,
          againPassword: false
        };
        initCountDown();
        await nextTick();
        ruleFormRef.value.resetFields();
        email.value = getLocalStorage('resetEmail') || '';
        ruleForm.value.email = email.value;
      }
    });
    watch(countDown, newCount => {
      if (newCount < 0) {
        initCountDown();
      }
    });

    //function
    /**关闭*/
    function close() {
      store.commit('setShowResetPwd', false);
    }

    /**初始化倒计时*/
    function initCountDown() {
      clearInterval(interval);
      showCountDown.value = false;
      countDown.value = 59;
    }

    /**发送邮件*/
    async function sendEmail() {
      if (!ruleForm.value.email) {
        alertMessage('error', t('rules.email'));
        return;
      }
      let patt = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!patt.test(ruleForm.value.email)) {
        alertMessage('error', t('rules.emailFormat'));
        return;
      }
      disabledEmail.value = true;
      let data = {
        email: ruleForm.value.email,
        userType: email.value ? 'APP' : 'IDE'
      };
      //倒计时
      clearInterval(interval);
      showCountDown.value = true;
      interval = setInterval(() => {
        countDown.value = countDown.value - 1;
      }, 1000);
      //获取结果
      let result = await sendCodeEmail(data);
      if (result.status === 0) {
        let text = t('success.sendEmail') + data.email;
        alertMessage('success', text);
      } else {
        let text = result ? result.msg || t('failed.sendEmail') : t('failed.sendEmail');
        alertMessage('error', text);
        initCountDown();
      }
    }

    /**提交*/
    async function submit(isBlur = false) {
      if (isBlur) blurAllInput('.reset-pwd-form');
      await nextTick();
      ruleFormRef.value.validate(async (valid, fields) => {
        if (valid) {
          let ruleFormVal = ruleForm.value;
          let data = {
            //email: ruleFormVal.email, //提交时去掉了邮箱 2023-05-25
            emailCode: ruleFormVal.emailCode,
            password: ruleFormVal.newPassword,
            userType: email.value ? 'APP' : 'IDE'
          };
          let result = await resetPassword(data);
          if (result.status === 0) {
            alertMessage('success', t('success.changePwd'));
            close();
          } else {
            let text = result ? result.msg || t('failed.changePwd') : t('failed.changePwd');
            alertMessage('error', text);
          }
        }
      });
    }
    return {
      //data
      dialogVisible,
      ruleForm,
      rules,
      email,
      passwordShow,
      countDown,
      showCountDown,
      disabledEmail,
      //ref
      ruleFormRef,
      //function
      close,
      sendEmail,
      submit
    };
  }
};