import "core-js/modules/es.array.push.js";
import MenuList from "./MenuList.vue";
import ViewContent from "./ViewContent.vue";
import { FullScreen, CopyDocument, Close } from "@element-plus/icons-vue";
import { driverPreviewUI, getPreviewUI } from "@/service/index.js";
import axios from "@/service/base";
export default {
  components: {
    MenuList,
    ViewContent,
    FullScreen,
    CopyDocument,
    Close
  },
  computed: {
    apiDoc() {
      return this.$store.state.apiDoc;
    }
  },
  data() {
    return {
      show: false,
      config: {},
      open: true,
      showMenu: false,
      width: 200,
      //导航列表对象
      subList: {
        httpInterfaces: [],
        features: [],
        driverEvents: [],
        irCommands: []
      },
      uiMaskBg: "rgba(0, 0, 0, 0.5)",
      //文档是否全屏
      isScreenView: false,
      //是否显示UI预览
      showLookUI: false,
      //UI预览是否全屏
      isScreenUI: false,
      //iframeSrc:'http://192.168.1.212:8082/index.html',
      iframeSrc: `${axios.defaults.baseURL.replace("/authorization-web", "")}page/module/jui/jui-drivePreview/index.html`,
      //ide版本号 IDE3.0.0以上新逻辑
      ideVersion: 0
    };
  },
  methods: {
    //初始化参数
    initConfig(data) {
      this.showMenu = false;
      this.ideVersion = this.getIdeVersion(data.ideVersion || "");
      this.subList = {
        httpInterfaces: [],
        features: [],
        driverEvents: [],
        irCommands: []
      };
      let driverPackageProtocols = data.driverPackageProtocols || [];
      //接口
      let protocolsInterfaces = this.initProtocols(driverPackageProtocols, data.httpInterfaces);
      this.subList.httpInterfaces = this.getSubListData(protocolsInterfaces);
      //功能
      let protocolsFeatures = this.initProtocols(driverPackageProtocols, data.features);
      this.subList.features = this.getSubListData(protocolsFeatures);
      //驱动事件
      let protocolsDriverEvents = this.initProtocols(driverPackageProtocols, data.driverEvents);
      this.subList.driverEvents = this.getSubListData(protocolsDriverEvents);
      //红外指令
      let protocolsIrCommands = this.initProtocols(driverPackageProtocols, data.irCommands);
      this.subList.irCommands = this.getSubListData(protocolsIrCommands);
      this.config = data;
      this.showMenu = true;
    },
    //获取语法文本
    getSyntaxHtml(name, params) {
      let arr = [];
      params.forEach((item, index) => {
        let res = `${index != 0 ? "," : " "}${item.type}${item.dimension != 0 ? "[] " : " "}${item.name}`;
        arr.push(res);
      });
      let str = arr.join("");
      let res = `${name}(${str})`;
      res = res.replace("( ", "(");
      res = res.replace(" )", ")");
      return res;
    },
    //选择了导航
    async handleSelect(name) {
      await this.$nextTick();
      this.scrollIntoView(name);
    },
    //滚动到可视区
    scrollIntoView(name) {
      if (name) {
        document.getElementById(name).scrollIntoView();
      } else {
        document.querySelector(".main-content").scrollTop = 0;
      }
    },
    //关闭
    handleClose() {
      this.$store.commit("setApiDoc", null);
    },
    //给第一级 设置一个parentId 并排序
    setParentIdToLevel(arr = [], parentId) {
      if (arr.length > 1) {
        //排序 文件夹排前面
        arr.sort((x, y) => {
          if (!x.parentId) x.parentId = parentId;
          if (!y.parentId) y.parentId = parentId;
          if (x.isFolder && !y.isFolder) {
            return -1;
          } else if (!x.isFolder && y.isFolder) {
            return 1;
          } else {
            let reg = /[a-zA-Z0-9]/;
            if (reg.test(x) || reg.test(y)) {
              if (x > y) {
                return 1;
              } else if (x < y) {
                return -1;
              } else {
                return 0;
              }
            } else {
              return x.localeCompare(y);
            }
          }
        });
      } else {
        arr.forEach(item => {
          if (!item.parentId) item.parentId = parentId;
        });
      }
      return arr;
    },
    //递归创建树
    mapTree(arr = [], pid) {
      let result = [];
      let temp;
      for (let i = 0; i < arr.length; i++) {
        let obj = arr[i];
        if (obj.parentId === pid) {
          temp = this.mapTree(arr, obj.id);
          if (temp.length > 0) {
            obj.children = temp;
          }
          result.push(obj);
        }
      }
      return result;
    },
    //初始化协议
    initProtocols(protocols, list) {
      let newList = typeof list === "string" ? JSON.parse(list) : list;
      if (!list || !list.length) return [];
      let newProtocols = typeof protocols === "string" ? JSON.parse(protocols) : protocols;
      if (!newProtocols || !newProtocols.length) return newList;
      //把数组归类到对应协议里面去
      let resArr = [];
      for (let i = 0; i < newProtocols.length; i++) {
        let itemP = newProtocols[i];
        itemP.children = [];
        for (let j = 0; j < newList.length; j++) {
          let itemL = newList[j];
          if (itemP.id == itemL.protocolId) {
            itemP.children.push(itemL);
          }
        }
        if (itemP.children.length) {
          resArr.push(itemP);
        }
      }
      if (!resArr.length) return newList;
      //排序
      resArr.sort((x, y) => {
        //时间戳
        let t1 = isNaN(Number(x.createTime)) ? 0 : Number(x.createTime);
        let t2 = isNaN(Number(y.createTime)) ? 0 : Number(y.createTime);
        return t1 - t2;
      });

      //转换为对象 {https1:[xxx],https2:[xxx]} 兼容getSubListData方法
      let resData = {};
      for (let p = 0; p < resArr.length; p++) {
        let k = resArr[p].name;
        let val = resArr[p].children;
        resData[k] = val;
      }
      return resData;
    },
    //获取文档最终数据
    getSubListData(data) {
      if (!data) return [];
      let newData = typeof data === "string" ? JSON.parse(data) : data;
      let parentIdToLevel = "level-first";
      //data为数组和对象两种类型
      if (Object.prototype.toString.call(newData) === "[object Array]") {
        let list = this.setParentIdToLevel(newData, parentIdToLevel);
        return this.mapTree(list, parentIdToLevel);
      } else {
        let res = [];
        for (let k in newData) {
          let item = newData[k];
          if (item.length) {
            let arr = {
              id: k,
              isFolder: true,
              name: k,
              children: []
            };
            let list = this.setParentIdToLevel(item, parentIdToLevel);
            let last = this.mapTree(list, parentIdToLevel);
            arr.children.push(...last);
            res.push(arr);
          }
        }
        return res;
      }
    },
    //初始化导航向右拖动功能
    initScrollRight() {
      let dom = this.$refs.mainBorderRef;
      let that = this;
      dom.onmousedown = function (e) {
        dom.style.borderColor = "#409eff";
        let left = document.querySelector(".ide-documentation-view-content").getBoundingClientRect().x;
        left < 0 ? 0 : left;
        let clientX = e.clientX - left;
        clientX = clientX < 200 ? 200 : clientX > 400 ? 400 : clientX;
        that.width = clientX; // 获取第一次拖动的坐标
        document.onmousemove = function (e) {
          let moveW = e.clientX - left;
          moveW = moveW < 200 ? 200 : moveW > 400 ? 400 : moveW;
          that.width = moveW;
        };
        //最后让他的鼠标按下和移动都为null，防止继续执行
        document.onmouseup = function () {
          dom.style.borderColor = "transparent";
          document.onmousemove = document.onmousedown = null;
        };
      };
    },
    //UI预览
    handleLookUI() {
      this.setIframe2Page();
      this.isScreenUI = false;
      this.showLookUI = true;
    },
    //设置UI预览iframe通讯方法
    setIframe2Page() {
      let id = this.$store.state.rowId;
      let type = this.$store.state.rowType;
      if (!id) return;
      if (type != 0 && type != 1) return;
      let baseURL = axios.defaults.baseURL.replace("/authorization-web", "");
      window.CallJava = async data => {
        let request = JSON.parse(data.request) || {};
        let method = request.method;
        if (method != "getDrivePreviewData") return;
        //通知接口下载UI预览数据
        let res = await driverPreviewUI(id, type);
        if (!res || res.status != 0) return;
        //获取下载数据
        let resData = await getPreviewUI(id, type);
        if (!resData) return;
        let driverImgPath = baseURL + `data/driverpreview/${id}/ui/`;
        let packageImgPath = baseURL + `data/packagepreview/${id}/ui/`;
        let imgPath = type == 0 ? driverImgPath : packageImgPath;
        let uiData = {
          data: {
            deviceId: id,
            pageWidth: resData.width,
            pageHeight: resData.height,
            children: this.getUITree(resData.pageTree)
          },
          imgPath: imgPath
        };
        data.onSuccess(JSON.stringify({
          data: uiData
        }));
      };
    },
    //获取驱动带UI预览数据
    getUITree(pageTree = []) {
      let arr = [];
      for (let i = 0; i < pageTree.length; i++) {
        let level1 = pageTree[i].children;
        for (let j = 0; j < level1.length; j++) {
          let level2 = level1[j];
          arr.push({
            id: level2.id,
            homePage: level2.homePage,
            properties: level2.properties
          });
        }
      }
      return arr;
    },
    //获取最低兼容版本-数字
    getIdeVersion(str = "") {
      //IDE文档不会传参数-其他几个端口会传参数
      if (!str) return 3;
      let first = str.charAt(0);
      let number = Number(first);
      return isNaN(number) ? 0 : number;
    }
  },
  watch: {
    apiDoc(newApiDoc) {
      if (!newApiDoc) {
        this.show = false;
      } else {
        this.initConfig(newApiDoc);
        this.isScreenView = false;
        this.show = true;
      }
    },
    async show(newShow) {
      if (newShow) {
        this.open = true;
        this.width = 200;
        await this.$nextTick();
        this.initScrollRight();
      } else {
        document.body.onclick = null;
      }
    }
  }
};