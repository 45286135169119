import "core-js/modules/es.array.push.js";
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { clearLocalStorage, setLocalStorage } from '@/assets/js/storage.js';
import { alertMessage, clearUserInfo } from '@/assets/js/tool.js';
import { logout, getUserInfo } from '@/service/index.js';
import { ElLoading } from 'element-plus';
import { getSummary } from '@/service/index.js';
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue';
import ExpiredTip from './Dialog/Tip.vue';
import Personal from './Dialog/Personal.vue';
import UpdateAuthorization from './Dialog/UpdateAuthorization.vue';
export default {
  components: {
    ArrowDown,
    ArrowUp,
    ExpiredTip,
    Personal,
    UpdateAuthorization
  },
  setup() {
    const {
      t,
      locale
    } = useI18n();
    //store
    const store = useStore();
    //用户信息
    const userInfo = computed(() => store.getters.getUserInfo);
    //多语言
    const lang = computed(() => store.getters.getLang);
    //点击退出后 需要跳转到首页的页面
    const logoutPage = computed(() => store.state.logoutPage);
    //到期提示
    const expirationTip = ref('');
    //到期天数
    const expirationDays = computed(() => {
      let days = store.state.expirationDays;
      expirationTip.value = days > 1 ? `${t('message.YourAccount')}${days}${t('message.ExpireInDays')}` : days == 1 ? t('message.LessThan1Day') : t('message.AccountExpired');
      return days;
    });

    //data
    const pathNow = ref('');
    let key = lang.value === 'en' ? 'english' : 'chinese';
    const langText = ref(t(`message.${key}`));
    const showLangMenu = ref(false); //显示多语言菜单
    const showUserMenu = ref(false); //显示用户信息菜单
    const showExpiredTip = ref(false); //显示到期提示
    const showPersonal = ref(false); //显示个人中心
    const showUpdateAuthorization = ref(false); //显示修改授权

    //router
    const router = useRouter();

    //watch
    watch(() => router.currentRoute.value.path, newPath => {
      pathNow.value = newPath;
      //能跳转到logoutPage数组里的页面 表示登录成功了 需要清除toPage
      if (logoutPage.value.indexOf(newPath) > -1) {
        //跳转到remote和account后清除toPage
        clearLocalStorage('toPage');
      }
    }, {
      immediate: true
    });
    watch(expirationDays, newVal => {
      if (newVal <= 30) showExpiredTip.value = true;
    });

    //function
    /**跳转到对应页面*/
    function goToPage(path, verify = false) {
      if (pathNow.value === path) return;
      //是否验证登录
      if (verify) {
        isLogin(path);
      } else {
        router.push(path);
      }
    }

    /**验证是否登录*/
    function isLogin(page) {
      let loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      //查询是否登录
      getSummary().then(res => {
        loading.close();
        if (res.status === 0) {
          router.push(page);
        } else if (res.status === -102) {
          //清除download
          clearLocalStorage('download');
          //设置toPage地址 登录成功后自动跳转到该地址
          setLocalStorage('toPage', page);
          //打开登录dialog
          store.commit('setShowLogin', true);
        } else {
          let text = res ? res.msg || t('failed.getData') : t('failed.getData');
          alertMessage('error', text);
        }
      });
    }

    /**清除stotage*/
    function clearStorage() {
      clearLocalStorage('download');
      clearLocalStorage('toPage');
    }

    /**点击登录*/
    function clickLogin() {
      showUserMenu.value = false;
      clearStorage();
      store.commit('setShowLogin', true);
    }

    /**点击注册*/
    function clickRegister() {
      showUserMenu.value = false;
      store.commit('setRegisterIdentity', 1);
      store.commit('setShowRegister', true);
    }

    /**点击退出登录*/
    async function clickLogout() {
      let result = await logout();
      if (result && result.status === 0) {
        clearUserInfo(); //清理用户缓存信息
        alertMessage('success', t('success.signOut'));
        let path = router.currentRoute.value.path;
        if (logoutPage.value.indexOf(path) > -1) router.push('/');
      } else {
        let text = result ? result.msg || t('failed.signOut') : t('failed.signOut');
        alertMessage('error', text);
      }
    }

    /**切换语言*/
    function checkLang(language, key) {
      store.commit('setLang', language);
      locale.value = language;
      let text = t(`message.${key}`);
      langText.value = text;
      showLangMenu.value = false;
    }

    /**点击修改密码*/
    function updatePassword() {
      store.commit('setShowUpdatePwd', true);
    }

    /**点击个人中心*/
    async function handleClickPersonal() {
      let loading = ElLoading.service({
        lock: true,
        background: 'rgba(0, 0, 0, 0)'
      });
      //获取审核是否通过
      let result = await getUserInfo();
      if (result && result.status === 0) {
        loading.close();
        let data = result.data || {};
        //未激活账号无到期时间
        data.expirationTime = data.expirationTime || '';
        //保存用户信息
        store.commit('setUserInfo', data);
        showPersonal.value = true;
      } else {
        loading.close();
        let text = result ? result.msg || t('message.OperationFailed') : t('message.OperationFailed');
        alertMessage('error', text);
      }
    }

    /**点击修改授权*/
    function handleUpdateAuthorization() {
      showUpdateAuthorization.value = true;
      showPersonal.value = false;
    }

    /**点击提交申请*/
    function handleApplicationSubmit() {
      showUpdateAuthorization.value = false;
      showPersonal.value = true;
    }
    return {
      //store
      userInfo,
      logoutPage,
      //data
      pathNow,
      langText,
      showLangMenu,
      showUserMenu,
      showExpiredTip,
      showPersonal,
      showUpdateAuthorization,
      expirationTip,
      expirationDays,
      //function
      goToPage,
      clickLogin,
      clickRegister,
      clickLogout,
      checkLang,
      handleClickPersonal,
      updatePassword,
      handleUpdateAuthorization,
      handleApplicationSubmit
    };
  }
};